.link {
    background: none;
    border: none;
    color: $color-text-black;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    outline: none;
    padding: 0;
    text-align: left;
    text-decoration: none;

    @include min-screen(768px) {
        font-size: 12px;
        line-height: 16px;
    }

    &--underline,
    &--active {
        border-bottom: 3px solid $color-border-black !important;
        height: 100%;
        text-decoration: none;
        // text-underline-position: under;
    }

    &--right {
        text-align: right;
    }

    &--awaiting-aproval,
    &--complete {
        color: $color-text-black-light;
        cursor: default;
    }

    &--light {
        font-weight: 300;
    }

    &--white {
        border-color: $color-text-white !important;
        color: $color-text-white;
        text-align: center;
        text-decoration-color: $color-text-white;
    }

    &--large {
        font-size: 16px;
    }

    &--highlight {
        color: red;
        text-decoration: underline;
    }
}
