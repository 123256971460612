.forms {
    padding-bottom: 60px;
    padding-top: 35px;
    @include min-screen(768px) {
        padding-bottom: 185px;
        padding-top: 35px;
    }
    .inner {
        position: relative;
        z-index: 10;
        @include min-screen(768px) {
            padding-top: 47px;
            &:first-child {
                padding-top: 35px;
            }
        }
    }
    &-title {
        color: $color-text-black;
        font-weight: 700;
        font-size: 22.4px;
        line-height: 30px;
        letter-spacing: 0px;
        margin-bottom: 0px;
        text-align: left;

        @include min-screen(768px) {
            font-size: 30px;
            line-height: 40px;
        }

        @include min-screen(1024px) {
        }
    }

    &-list {
        @include min-screen(768px) {
            display: flex;
            flex-flow: row wrap;
            //justify-content: center;
        }
    }

    &-list-single {
        background-color: $color-background-white;
        border-radius: 8px;
        padding: 8px 14px;
        margin-top: 20px;
        position: relative;

        @include min-screen(768px) {
            padding: 15px 20px 40px;
            width: calc(100% / 3 - 14px);
            &:nth-child(3n + 2) {
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        @include min-screen(1024px) {
            width: calc(100% / 4 - 15px);
            &:nth-child(1n) {
                margin-right: 0px;
                margin-left: 20px;
            }

            &:nth-child(4n + 1) {
                margin-right: 0px;
                margin-left: 0px;
            }
        }

        @include min-screen(1440px) {
            width: calc(100% / 5 - 16px);
            &:not(:first-child) {
                margin-right: 0px;
                margin-left: 20px;
            }
        }

        &__title {
            color: $color-text-black;
            font-weight: 700;
            font-size: 22.4px;
            hyphens: auto;
            line-height: 30px;
            letter-spacing: 0px;
            text-align: left;
            word-break: break-word;

            @include min-screen(768px) {
                font-size: 24px;
                line-height: 28px;
                // max-width: 164px;
            }

            span {
                color: red;
            }
        }

        &__text {
            color: $color-text-black;
            font-weight: 500;
            font-size: 11.2px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0px;
            text-align: left;
            padding-bottom: 10px;

            @include min-screen(768px) {
                font-size: 14px;
                line-height: 19px;
                // max-width: 180px;
                padding-bottom: 20px;
                padding-top: 15px;
                &--gap {
                    padding-top: 50px;
                    padding-bottom: 40px;
                }
            }
        }

        &__info {
            @include flex-between();

            @include min-screen(768px) {
                bottom: 20px;
                left: 15px;
                position: absolute;
                right: 15px;
            }

            &__status {
                text-transform: uppercase;
            }
            &__link {
                align-self: center;
                color: $color-text-black;
                font-weight: 700;
                font-size: 9.6px;
                line-height: 13px;
                letter-spacing: 0px;
                text-align: left;
                text-decoration: none;
            }

            &--end {
                justify-content: flex-end;
                @include min-screen(768px) {
                    justify-content: flex-start;
                }
            }
        }
    }
}
