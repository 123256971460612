body {
    background-color: $color-background-grey-light;
}

.main {
    // min-height: calc(100vh - 95px);
    //padding-bottom: 95px;
    // margin-bottom: 95px;
    @include min-screen(768px) {
        //padding-bottom: 95px;
    }
}

.wrapper {
    width: (298px/375px) * 100vw;
    margin-left: auto;
    margin-right: auto;

    @include min-screen(768px) {
        width: 421px;
        // max-width: 421px;
    }
    &--overlay-complete {
        filter: blur(11.93px);
    }
    &--gap {
        padding-top: 56px;
        padding-bottom: 42px;
        @include min-screen(768px) {
            padding-top: 56px;
            padding-bottom: 42px;
        }

        &--small {
            padding-top: 30px;
            padding-bottom: 30px;
            @include min-screen(768px) {
                padding-top: 56px;
                padding-bottom: 42px;
            }
        }

        &--smallest {
            padding-top: 18px;
            padding-bottom: 30px;
            @include min-screen(768px) {
                padding-top: 56px;
                padding-bottom: 42px;
            }
        }
    }

    &--left {
        @include min-screen(768px) {
            // margin-left: 50px;
        }
    }

    &--auto {
        @include min-screen(768px) {
            margin-left: 50px;
            margin-right: 50px;
            width: auto;
        }
    }

    // &.small{
    //     max-width: 873px;
    //     width: (343px/375px) * 100vw;
    //     @include min-screen(768px) {
    //         width: (564px/768px) * 100vw;
    //     }

    //     @include min-screen(768px) {
    //         width: (700px/768px) * 100vw;
    //     }

    //     @include min-screen(1440px) {
    //         width: 873px;
    //     }
    // }
}

.inner {
    // height: 100%;
    margin: 0 auto;
    max-width: 1270px;
    position: relative;
    width: (320px/375px) * 100vw;

    @include min-screen(768px) {
        width: (654px/768px) * 100vw;
    }

    @include min-screen(1024px) {
        width: (894px/1024px) * 100vw;
    }

    @include min-screen(1440px) {
        width: 1270px;
    }

    &--background {
        background-color: #ffffff;
        border-radius: 12px;
        // padding: 8px 14px;
    }

    &.small {
        max-width: 873px;
        width: (343px/375px) * 100vw;
        @include min-screen(768px) {
            width: (564px/768px) * 100vw;
        }

        @include min-screen(768px) {
            width: (700px/768px) * 100vw;
        }

        @include min-screen(1440px) {
            width: 873px;
        }
    }

    &.half {
        max-width: 873px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        // width: (343px/375px) * 100vw;

        @include min-screen(768px) {
            margin-right: auto;
            margin-left: auto;
            width: (700px/768px) * 100vw;
        }

        @include min-screen(1024px) {
            margin-right: 0px;
            margin-left: 50px;
            width: (874px/1440px) * 100vw;
        }

        // @include min-screen(1440px) {
        //     margin-right: 0px;
        //     margin-left: 0px;
        //     width: 873px;
        // }
    }

    &.wide {
        max-width: 1310px;
        width: (315px/375px) * 100vw;

        @include min-screen(768px) {
            width: (664px/768px) * 100vw;
        }

        @include min-screen(1440px) {
            width: 1310px;
        }

        &.not-mobile {
            width: auto;

            @include min-screen(768px) {
                align-items: flex-start;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: (690px/768px) * 100vw;
            }

            @include min-screen(1440px) {
                width: 1310px;
            }
        }
    }
    &.desktop-flex {
        @include min-screen(768px) {
            align-items: flex-start;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
    &.center {
        align-items: center;
        display: flex;
        height: calc(100vh - 169px);
        position: relative;

        @include min-screen(768px) {
            height: calc(100vh - 180px);
        }
    }
}
