.privacy,
.terms {
    position: relative;
    padding-top: 66px;
    padding-bottom: 42px;
    z-index: 10;
    @include min-screen(768px) {
        padding-top: 72px;
        padding-bottom: 102px;
    }

    @include min-screen(1440px) {
        padding-top: 32px;
        padding-bottom: 56px;
    }
}