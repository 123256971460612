.view-team {
    .heading-group {
        margin-bottom: 0px;
        @include min-screen(768px) {
            margin-bottom: 15px;
        }
    }
    .team-member-details {
        &:not(:first-child) {
            border-top: 2px solid $color-border-black;
            padding-top: 20px;
        }
        &:first-child {
            padding-top: 28px;
            padding-bottom: 20px;
            @include min-screen(768px) {
                padding-bottom: 5px;
            }
        }
        @include min-screen(768px) {
        }
    }
    .personal-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
        hgroup {
            @include min-screen(768px) {
                display: flex;
                flex-wrap: wrap;
            }

            & + a {
                margin-top: 3px;
            }
        }
        &__name {
            color: $color-text-black;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 0px;

            @include min-screen(768px) {
                display: flex;
                span {
                    white-space: pre;
                }
            }
        }
        &__email {
            color: $color-text-black;
            font-weight: 300;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0px;
            @include min-screen(768px) {
                font-size: 20px;
                line-height: 27px;
            }
        }
    }

    .form-details {
        @include min-screen(768px) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        .container {
            @include min-screen(768px) {
                display: flex;
                flex-wrap: wrap;
            }
        }
        &__form {
            display: flex;
            @include min-screen(768px) {
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
            p {
                margin-bottom: 10px;
            }
        }
    }
    .status {
        align-self: center;
        color: $color-text-black;
        font-weight: 700;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0px;
        min-height: 30px;
        white-space: nowrap;

        span {
            font-weight: 300;
        }
    }
}
