body {
    color: #000;
    font-family: $font-stack;

    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

h1, h2, h3 {
    margin-top: 0;
}

li,
p {

}

a {

}
