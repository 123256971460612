.heading-group {
    margin-bottom: 15px;

    &--margin-top {
        margin-bottom: 0;
        margin-top: 50px;
    }

    &--margin-top-small {
        margin-bottom: 0;
        margin-top: 10px;
    }

    &--small {
        margin-left: auto;
        margin-right: auto;
        padding-top: 55px;
        padding-bottom: 20px;
        max-width: 774px;
        width: (279px/375px) * 100vw;
        @include min-screen(768px) {
            width: (611px/768px) * 100vw;
        }
        @include min-screen(1440px) {
            width: (774px/768px) * 100vw;
        }
    }

    &__title {
        color: $color-text-black;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: 0px;
        text-align: left;
        @include min-screen(768px) {
            font-size: 40px;
            line-height: 53px;
            &--reduce {
                line-height: 38px;
            }
        }

        &--small {
            color: $color-text-black;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            @include min-screen(768px) {
                font-size: 40px;
                line-height: 53px;
            }
        }
        &--medium {
            font-size: 29.94px;
            line-height: 40px;
            @include min-screen(768px) {
                font-size: 36px;
                line-height: 48px;
            }
        }
        &--custom {
            font-size: 29.94px;
            line-height: 40px;
            @include min-screen(768px) {
                font-size: 40px;
                line-height: 53px;
            }
        }

        &--warning {
            color: #fe6259;
        }
    }
    &__subtitle {
        color: $color-text-black;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.67px;
        text-align: left;
        @include min-screen(768px) {
            font-size: 20px;
            line-height: 27px;
        }
        &--small {
            font-size: 12px;
            line-height: 16px;
            @include min-screen(768px) {
                font-size: 20px;
                line-height: 27px;
            }
        }
        &--medium {
            font-weight: 500;
            font-size: 14.97px;
            line-height: 20px;
            // @include min-screen(768px) {
            //     font-size: 18px;
            //     line-height: 24px;
            // }
        }

        &--warning {
            color: #fe6259;
        }
    }
}
