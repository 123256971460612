.home {
	height: 400px;
	left: 50%;
	position: absolute;
	top: 40%;
	transform: translateX(-50%) translateY(-50%);
	width: 400px;

	h1 {
		font-size: 26px;
		letter-spacing: 1px;
		margin-bottom: 10px;
	}

	p {
		font-size: 14px;
		letter-spacing: 0.5px;
		margin-bottom: 30px;
	}

	img {
		border-radius: 4px;
		height: 100%;
		width: 100%;
		box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.2)
	}
}
