.desktop {
    display: none !important;
    @include min-screen(1024px) {
        display: flex !important;
    }
}

.tablet {
    display: none !important;
    @include min-screen(768px) {
        display: block !important;
    }
}

.mobile {
    display: block !important;
    @include min-screen(1024px) {
        display: none !important;
    }
}

