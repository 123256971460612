// color variables

// Block Colors
$color-white: #ffffff;

// text Colors
$color-text-black: #4a4a4a;
$color-text-blue: #008efe;
$color-text-black-light: rgba(#4a4a4a, 0.34);
$color-text-black-medium: rgba(#4a4a4a, 0.75);
$color-text-white: #ffffff;

// background Colors

$color-background-grey-light: #f2f2f2;
$color-background-white: #ffffff;
$color-background-blue: #008efe;

//border
$color-border-blue: #008efe;
$color-border-black: #4a4a4a;

// Button Colors
$color-button-text-white: #ffffff;
$color-button-text-blue: #008efe;
$color-button-background-white: #ffffff;
$color-button-background-blue: #008efe;
