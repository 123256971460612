.footer {
    // position: fixed;
    // bottom: 0px;
    // width: 100%;
    position: relative;

    &__content {
        background-color: $color-background-white;
        border-top: 11px solid $color-border-blue;
        display: flex;
        flex-flow: row wrap;
        height: 95px;
        position: relative;
        z-index: 9999;
    }

    &__image {
        img {
            bottom: 0px;
            position: absolute;
            left: 0px;
            width: 19%;

            @include min-screen(768px) {
                bottom: 75px;
            }
        }
    }
    .inner {
        @include flex-between;
        flex-flow: column wrap;
        margin: auto;

        @include min-screen(768px) {
            flex-flow: row nowrap;
        }
    }
    &__nav {
        align-items: center;
        display: flex;
        justify-content: space-between;
        max-width: 120px;

        @include min-screen(768px) {
            max-width: none;
        }
        a {
            color: $color-text-black-light;
            font-weight: 400;
            font-size: 10px;
            line-height: 19px;
            letter-spacing: 0px;
            text-decoration: none;
            @include min-screen(768px) {
                &:not(:first-child):not(:last-child) {
                    margin-left: 21px;
                    margin-right: 21px;
                }
            }
        }
    }

    &__copyright {
        display: flex;
        @include min-screen(1024px) {
            align-self: center;
        }
        p {
            color: $color-text-black-light;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0px;
        }
    }
}
