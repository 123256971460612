.header {
    padding-top: 34px;
    @include min-screen(1024px) {
        padding-top: 47px;
    }
    &__image {
        background-image: url("../img/asset.top.mobile.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 38px -45px;
        height: 50%;
        position: absolute;
        right: 0px;
        top: 0px;
        width: (195px/375px) * 100vw;

        @include min-screen(768px) {
            background-image: url("../img/asset.top.tablet.png");
            background-position: 100px 0px;
            // right: -100px;
            // top: 0px;
            // background-image: url("img/asset.top.desktop.png");
            // background-size: cover;
            // background-position: 0px -30px;
            // width: (400px/768px) * 100vw;
            width: (390px/768px) * 100vw;
        }

        @include min-screen(1024px) {
            background-image: url("../img/asset.top.desktop.png");
            background-position: 0px 0px;
            width: (618px/1024px) * 100vw;
        }

        @include min-screen(1440px) {
            width: (1034px/1440px) * 100vw;
            max-width: 1034px;
        }
    }

    .nav-top {
        @include flex-between;
        width: 100%;

        &__right {
            position: relative;
            z-index: 99999;
        }

        &__left {
            z-index: 999;
            @include min-screen(1024px) {
                padding-top: 15px;
            }
            &__item {
                color: $color-text-black;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: 0px;
                text-align: left;
                text-decoration: none;

                @include min-screen(768px) {
                    font-size: 36px;
                    line-height: 48px;
                    text-align: left;
                }
            }
        }

        .menu-toggle {
            align-content: center;
            background: none;
            border: 0;
            cursor: pointer;
            height: 23px;
            padding: 0;
            outline: none;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            width: 22px;
            z-index: 999;

            span {
                @include burger(0px, none);
                &::before {
                    @include burger(8px);
                }
                &::after {
                    @include burger(16px);
                }
            }

            @include min-screen(1024px) {
                //display: none;
            }
        }
    }
    .nav-mobile {
        display: none;
    }
}

.nav-open {
    overflow: hidden;
    footer {
        display: none;
    }
    .nav-top__left__item {
        color: $color-text-white;
    }
    .nav-mobile {
        height: 100%;
        background: #60c9fa;
        display: flex;
        flex-direction: column;
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        &__top {
            // display: flex;
            // flex-direction: column;
            margin: 0 auto;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

            &__title {
                color: $color-text-white;
                font-weight: 700;
                font-size: 30.72px;
                line-height: 41px;
                letter-spacing: 0px;
                margin-bottom: 12px;
                text-decoration: none;
                text-align: center;

                @include min-screen(768px) {
                    font-size: 48px;
                    line-height: 64px;
                }
            }

            .container {
                display: flex;
                flex-direction: column;
                @include min-screen(768px) {
                    margin-bottom: 20px;
                }
            }
        }

        &__bottom {
            align-self: flex-end;
            display: flex;
            flex-direction: column;
            margin: auto auto 40px auto;
            &__link {
                display: flex;
                justify-content: center;
                a {
                    font-size: 16.13px;
                    line-height: 21px;
                    margin-top: 30px;
                    @include min-screen(768px) {
                        font-size: 25.2px;
                        line-height: 34px;
                    }
                }
            }
            h3 {
                color: $color-text-white;
                font-weight: 700;
                font-size: 16.13px;
                line-height: 21px;
                letter-spacing: 0px;
                text-align: center;
                @include min-screen(768px) {
                    font-size: 25.2px;
                    line-height: 34px;
                }
            }
            h6 {
                color: $color-text-white;
                font-weight: 300;
                font-size: 16.13px;
                line-height: 21px;
                letter-spacing: 0px;
                text-align: center;
                @include min-screen(768px) {
                    font-size: 25.2px;
                    line-height: 34px;
                }
            }
        }
    }
}
