.inline-list {
    font-size: 0;
    margin: 0;
    list-style: none;
    padding: 0;

    &__item {
        display: inline-block;
        vertical-align: top;
    }
}

.unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.bulleted-list {
    list-style-type: disc;
    margin: 0;
    padding-left: 1em;
}
