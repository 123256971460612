.nav-sidebar {
    //position: fixed;
    display: flex;
    flex-direction: column;
    margin-top: 52px;
    width: 320px;
    .container {
        &:last-child {
            margin-top: 60px;
        }
        h2 {
            margin-bottom: 18px;
            color: $color-text-black;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 0px;
        }
    }
    // @include min-screen(1024px) {
    //     // width: 1040px;
    //     margin-left: auto;
    // }
    // @include min-screen(1440px) {
    //     max-width: 960px;
    //     width: (960px/1440px) * 100vw;
    //     // width: 960px;
    //     margin-left: auto;
    //     // margin-right: 87px;
    // }

    &__link {
        border-bottom: 3px solid transparent;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;;
        white-space: nowrap;
    }

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;

        > .btn {
            flex-shrink: 0;
            margin: 0 0 0 10px;
        }

        span {
            color: red;
        }
    }
}
