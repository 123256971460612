.btn {
    align-items: center;
    border: none;
    border-radius: 50px;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    justify-content: center;
    text-decoration: none;
    line-height: 19px;
    letter-spacing: 0px;
    outline: none;

    &--status {
        width: auto;
        // height: auto;
        height: 20px;
        padding: 1px 8.5px 0px 8.5px;
        font-size: 9.6px;
        line-height: 13px;

        & + .btn--status {
            border-left: none !important;
        }
    }

    &--radius {
        border-radius: 2px;
    }

    &--right {
        text-align: left;
        margin-right: auto;
        @include min-screen(768px) {
            text-align: right;
            margin-left: auto;
            margin-right: 0px;
        }
    }

    &--shadow {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);
    }

    &--full {
        background-color: $color-button-background-white;
        color: $color-button-text-blue;
        height: 38px;
        width: 114px;
        &--blue {
            background: $color-button-background-blue;
            color: $color-button-text-white;
            font-size: 16.2px;
            height: 40.5px;
            line-height: 22px;
            width: 130px;
            // text-transform: uppercase;
        }
        &--incomplete {
            border: 0.8px solid $color-border-black;
            background: $color-background-white;
            color: $color-text-black;
            text-transform: uppercase;
        }
        &--complete {
            border: 0.8px solid #31c943;
            background: rgba(#29c940, 0.4);
            color: #31c943;
            text-transform: uppercase;
        }
        &--action-needed {
            border: 0.8px solid #fe6259;
            background: #f5bfbc;
            color: #fe6259;
            text-transform: uppercase;
        }
        &--awaiting-approval,
        &--resend {
            border: 0.8px solid #febf34;
            background: #f6e0ae;
            color: #febf34;
            text-transform: uppercase;
        }

        &--wide {
            height: 40.5px;
            width: 176px;
        }
        &--no-background {
            background: $color-background-white;
        }
    }

    &--square {
        border-radius: 0px;
    }
}
