.text-block {
    padding-bottom: 28px;
    p {
        color: $color-text-black;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 22px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 32px;
        text-align: left;
        width: (279px/375px) * 100vw;
        max-width: 774px;
        @include min-screen(768px) {
            width: (611px/768px) * 100vw;
        }

        @include min-screen(1440px) {
            width: (774px/768px) * 100vw;
        }
    }
}
