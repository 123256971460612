* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

html {
    font-size: 1em;
    line-height: 1.4;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

body {
    background-color: #fff;
    overflow-x: hidden;
    width: 100%;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

audio,
canvas,
img,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

/* ==========================================================================
   Browse Happy prompt
   ========================================================================== */

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


.add-team-member,
.form-page,
.view-team {
    position: relative;
    padding-top: 66px;
    padding-bottom: 42px;
    z-index: 10;

    @include min-screen(768px) {
        padding-top: 72px;
        padding-bottom: 102px;
    }

    @include min-screen(1024px) {
        padding-top: 32px;
        padding-bottom: 56px;
    }
}
