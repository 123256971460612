.overlay {
    position: absolute;
    background: rgba(51, 203, 72, 0.55);
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    border-radius: 12px;
    z-index: 10;
    .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    &__title {
        color: $color-text-white;
        font-weight: bold;
        font-size: 48px;
        line-height: 64px;
        letter-spacing: 0px;
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
        @include min-screen(768px) {
            font-size: 48px;
            line-height: 85px;
        }
    }
    &__next-form {
        color: $color-text-white;
        font-weight: 300;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0px;
        margin-top: 15px;
        text-align: center;
        text-transform: uppercase;
        @include min-screen(768px) {
            font-size: 12px;
            line-height: 16px;
        }
        span {
            font-weight: bold;
        }
    }
}
