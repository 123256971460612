.form {
    width: 100%;

    &__group {
        display: flex;
        flex-direction: column;
        .upload-icon {
            margin-right: 15px;
            margin-bottom: 22px;
            img {
                height: 66px;
                width: auto;
            }
        }
        &--gap {
            padding-bottom: 20px;
        }
        &--border {
            border-bottom: 2px solid $color-border-black;
            padding-bottom: 20px;
        }
        &--text,
        &--upload,
        &--dropdown {
            label {
                display: none;
            }
        }
        &__holder {
            display: flex;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--radio {
            h6 {
                color: $color-text-black;
                font-weight: 300;
                font-size: 14.97px;
                line-height: 20px;
                letter-spacing: 0px;
                @include min-screen(768px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            input {
                position: absolute;
                left: -9999px;
                &:checked + label::after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
            label {
                color: $color-text-black;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0px;
                padding: 4px 0px 3px 30px;
                position: relative;
                text-align: left;
                // text-transform: uppercase;
                &.status {
                    font-size: 11.64px;
                    line-height: 15px;
                    padding: 0px;
                    @include min-screen(768px) {
                        font-size: 14px;
                        line-height: 19px;
                    }
                    &--awaiting-aproval {
                        &::before {
                            border-color: #febf34;
                            top: 20px;
                        }
                        &::after {
                            background: #febf34;
                            top: 26px;
                        }
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 17px;
                    height: 17px;
                    border: 2px solid $color-text-black;
                    border-radius: 100%;
                    background: transparent;
                }

                &::after {
                    content: "";
                    opacity: 0;
                    width: 9px;
                    height: 9px;
                    background: $color-text-black;
                    position: absolute;
                    top: 9px;
                    left: 6px;
                    border-radius: 100%;
                }
            }
        }

        &--between {
            justify-content: space-between;
        }
        &--inline {
            flex-direction: row;
        }

        &--mobile-column {
            flex-direction: column;
            @include min-screen(768px) {
                flex-direction: row;
            }
        }

        &--fixed-width {
            max-width: 414px;
        }

        &--padded {
            padding: 20px 0px 30px 0px;
        }

        &__title {
            color: $color-text-black;
            font-weight: 700;
            font-size: 10.8px;
            line-height: 14px;
            letter-spacing: 0px;
            padding: 15px 0px 10px 5px;
            text-align: left;
            text-transform: uppercase;

            @include min-screen(768px) {
                font-size: 12px;
                line-height: 16px;
            }

            &--reset-top {
                padding: 0px 0px 10px 5px;
            }

            &--space {
                padding: 20px 0px 10px 5px;
                @include min-screen(768px) {
                    padding: 25px 0px 10px 5px;
                }
            }
        }
        &__half {
            display: flex;
            flex-direction: column;
            width: calc(100% / 2 - 9px);
            &--reset-width {
                width: auto;
            }
        }

        // &--checkbox {
        //     flex-wrap: wrap;

        //     input {
        //         position: absolute;
        //         left: -9999px;
        //         &:checked + label::after {
        //             opacity: 1;
        //         }
        //     }
        //     label {
        //         color: $color-text-black-light;
        //         font-weight: 500;
        //         font-size: 10px;
        //         letter-spacing: 0px;
        //         line-height: 13px;
        //         position: relative;
        //         padding-left: 40px;
        //         padding-top: 2px;
        //         text-align: left;
        //         text-transform: none;
        //         width: 100%;
        //         &::before {
        //             content: "";
        //             position: absolute;
        //             left: 0px;
        //             top: 0;
        //             width: 16.2px;
        //             height: 16.2px;
        //             background-color: $color-background-white;
        //         }
        //         &::after {
        //             content: "";
        //             position: absolute;
        //             display: block;
        //             width: 4px;
        //             height: 9px;
        //             border: 2px solid $color-text-black;
        //             border-width: 0 3px 3px 0;
        //             left: 5px;
        //             top: 0px;
        //             -webkit-transform: rotate(45deg);
        //             transform: rotate(45deg);
        //             opacity: 0;
        //         }
        //         span,
        //         a {
        //             color: inherit;
        //             font-weight: 700;
        //             text-decoration: none;
        //         }

        //         &.dark::before {
        //             background-color: $color-background-grey-light;
        //         }
        //     }
        // }


        &--checkbox {
            h6 {
                color: $color-text-black;
                font-weight: 300;
                font-size: 14.97px;
                line-height: 20px;
                letter-spacing: 0px;
                @include min-screen(768px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            input {
                position: absolute;
                left: -9999px;
                &:checked + label::after {
                    opacity: 1;
                    // transform: scale(1);
                }
            }
            label {
                color: $color-text-black;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0px;
                padding: 4px 0px 3px 30px;
                position: relative;
                text-align: left;
                // text-transform: uppercase;
                &.status {
                    font-size: 11.64px;
                    line-height: 15px;
                    padding: 0px;
                    @include min-screen(768px) {
                        font-size: 14px;
                        line-height: 19px;
                    }
                    &--awaiting-aproval {
                        &::before {
                            border-color: #febf34;
                            top: 20px;
                        }
                        &::after {
                            background: #febf34;
                            top: 26px;
                        }
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 17px;
                    height: 17px;
                    border: 2px solid $color-text-black;
                    // border-radius: 100%;
                    background: transparent;
                }

                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 4px;
                    height: 9px;
                    border: 2px solid $color-text-black;
                    border-width: 0 3px 3px 0;
                    left: 7px;
                    top: 6px;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    opacity: 0;
                }

                // &::after {
                //     content: "";
                //     opacity: 0;
                //     width: 9px;
                //     height: 9px;
                //     background: $color-text-black;
                //     position: absolute;
                //     top: 9px;
                //     left: 6px;
                //     border-radius: 100%;
                // }
            }
        }

        p {
            color: $color-text-black;
            font-weight: 300;
            font-size: 9.98px;
            line-height: 12.474px;
            letter-spacing: 0px;
            padding-left: 5px;
            padding-bottom: 12px;
            @include min-screen(768px) {
                font-size: 12px;
                line-height: 15px;
            }
        }

        .input {
            &--text {
                border: none;
                border-radius: 6px;
                // height: 45px;
                padding-left: 15px;
                width: 100%;
                @include input-placeholder {
                    color: $color-text-black-light;
                    font-weight: 300;
                    font-size: 16.2px;
                    line-height: 22px;
                    letter-spacing: 0px;
                    text-align: left;
                }
                &--half {
                    //width: calc(100% / 2);
                }
            }
            &--height {
                height: 37px;
                @include min-screen(768px) {
                    height: 45px;
                }
            }
            &--multi {
                padding-bottom: 11px;
                padding-top: 12px;
            }
            &--checkbox {
            }

            &--border {
                border: 2px solid $color-border-black;
            }
        }

        .file-upload {

            input[type="file"] {
                border: 1px solid transparent;
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .btn {
                position: relative;
            }

            p {
                margin: 10px 0 0;
            }
        }
    }

    // textarea {
    //     border: none;
    //     border-radius: 6px;
    //     height: 45px;
    //     padding-left: 15px;
    //     width: 100%;
    //     @include input-placeholder {
    //         color: $color-text-black-light;
    //         font-weight: 300;
    //         font-size: 16.2px;
    //         line-height: 22px;
    //         letter-spacing: 0px;
    //         text-align: left;
    //     }
    // }

    &__dropdown {
        position: relative;

        select {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;

            background: white;
        }

        &::after {
            content: "";
            color: #4a4a4a;
            right: 40px;
            bottom: 5px;
            height: 26px;
            border-left: 1px solid #4a4a4a;
            position: absolute;
            pointer-events: none;

            @include min-screen(768px) {
                height: 32px;
                bottom: 7px;
            }
        }

        &::before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 10px solid #4a4a4a;
            position: absolute;
            bottom: 14px;
            right: 15px;
            transform: rotate(270deg);

            @include min-screen(768px) {
                bottom: 17px;
            }
        }
    }

    &__error {
        color: red;
        font-size: 10.8px;
        line-height: 14px;
        margin: 5px 0 0;

        @include min-screen(768px) {
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__file-upload-name {
        color: green !important;
        font-weight: 700 !important;
    }

    .uppy-DragDrop--is-dragdrop-supported {
        border: 2px solid $color-border-black !important;
    }

    .uppy-DragDrop-inner {
        padding: 20px !important;
    }

    .uppy-DragDrop-arrow {
        display: none !important;
    }
}
